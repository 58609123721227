<template>
    <div>
        <!-- banner -->
        <section>
            <div class="headerimg overflow-hidden text-center">
                <div id="carouselExampleCaptions" class="carousel">
                    <div class="carousel-inner position-relative">
                        <div class="active">
                            <img src="../image/partner/banner.jpg" class="d-md-block d-none w-100  img-fluid" alt="...">
                            <img src="../image/partner/banner-mobile.jpg"
                                class="d-block d-md-none w-100  bannner-bg img-fluid" alt="...">

                            <div class="carousel-difimg position-absolute text-white ">
                                <!-- <p class=" pt-3 carousel-title">EarthSDK·招募项目合伙人</p> -->
                                <p><img src="../image/partner/business/pc.png" alt="" class="img-fluid py-3"></p>
                                <p class="btn lh-1  btn-add " @click="scrollToAnchor('code')">立即加入</p>
                            </div>
                            <div class="pb-4  position-absolute text-white banner-txt d-none d-md-block">
                                <h3 class="text-center fw-bold pb-3">大时代来临</h3>
                                <p class="text-center text-white">近年来，数字孪生行业逐渐火热，各地可视化项目逐渐增多</p>
                                <p class="text-center text-white">而真正的数字孪生，不仅仅是模型，核心是物联网数据与虚拟世界的联系，模拟真实世界的变化</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 大时代来临 -->
        <section class="bg-section">
            <div class="container-custom ">
                <div class="row row-cols-2 row-cols-md-4  text-start   text-white  mobile-flex">
                    <div class="col d-flex justify-content-center g-md-2 gx-2 g-md-0 mobile-img">
                        <div class="bg-introduce1 bg-introduce-base p-3 d-flex flex-column justify-content-between ">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text">
                                <span>智慧水务</span>
                            </div>
                            <div class="text-wrap pt-3  mt-auto d-none d-md-block">全面提升城市水资源管理、水生态保护、水环境改善及水安全保障的能力
                            </div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-md-2 gx-2 g-md-0 mobile-img">
                        <div class="bg-introduce2 bg-introduce-base p-3 d-flex flex-column justify-content-between">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text ">
                                <span>智慧港口</span>
                            </div>
                            <div class="text-wrap pt-3 d-none d-md-block">构建一个覆盖流程贯通、业务融合、场景监控的智慧港口操作系统</div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center  g-md-2 g-2 g-md-0 mobile-img">
                        <div class="bg-introduce3 bg-introduce-base p-3 d-flex flex-column justify-content-between">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text">
                                <span>智慧林业</span>
                            </div>
                            <div class="text-wrap pt-3 d-none d-md-block">依照林业系统中的小班数据，批量生成森林切片数据，实现林业数字孪生可视化</div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-md-2 g-2 g-md-0 mobile-img">
                        <div class="bg-introduce4 bg-introduce-base p-3 d-flex flex-column justify-content-between">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text">
                                <span>智慧园区</span>
                            </div>
                            <div class="text-wrap pt-3 d-none d-md-block">推进园区管理的数字化、智能化转型，实现资源的高效配置和运营管理的优化</div>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-2 row-cols-md-4  text-start pb-5  text-white  mobile-flex">
                    <div class="col d-flex justify-content-center g-md-2 g-2 g-md-0 mobile-img ">
                        <div class="bg-introduce5 bg-introduce-base p-3 d-flex flex-column justify-content-between ">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text">
                                <span>智慧交通</span>
                            </div>
                            <div class="text-wrap pt-3  mt-auto d-none d-md-block">通过数字化手段降低运营风险，为轨道交通系统的升级与发展提供坚实基础
                            </div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-md-2 g-2 g-md-0 mobile-img">
                        <div class="bg-introduce6 bg-introduce-base p-3 d-flex flex-column justify-content-between">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text">
                                <span>智慧工厂</span>
                            </div>
                            <div class="text-wrap pt-3 d-none d-md-block">通过综合监控与分析生产过程，优化生产管理，提升生产效率和产品质量</div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-md-2 g-2 g-md-0 mobile-img">
                        <div class="bg-introduce7 bg-introduce-base p-3 d-flex flex-column justify-content-between">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text">
                                <span>智慧场馆</span>
                            </div>
                            <div class="text-wrap pt-3 d-none d-md-block">通过统一管理安保、人员、设备及赛事数据，创建一个集成的智慧场馆管理系统</div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-md-2 g-2 g-md-0 mobile-img">
                        <div class="bg-introduce8 bg-introduce-base p-3 d-flex flex-column justify-content-between">
                            <div class="pt-md-1 pt-4  bg-introduce-mobile-text">
                                <span>智慧能源</span>
                            </div>
                            <div class="text-wrap pt-3 d-none d-md-block">本解决方案为电力、石油化工及煤炭等能源全产业链提供了一种新的提质增效途径</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- 合作共赢 -->
        <section>
            <div class="bg-cooperation container-custom  text-white  text-wrap">
                <!-- 1 -->
                <div>
                    <h3 class="text-center fw-bold   pt-md-5 pb-md-4 py-3">合作共赢</h3>
                    <p class="text-center text-white d-none d-md-block">EarthSDK，一款开源且免费的地球可视化二次开发框架，凭借多年的实际项目经验，</p>
                    <p class="text-center text-white d-none d-md-block">
                        已成为此领域内的重要工具。我们秉承平等互利、优势互补、共赢发展的合作原则，现诚意招募市场战略合作伙伴。</p>
                    <p class="text-center text-white d-none d-md-block">
                        通过合作，我们期望能够为合作伙伴提供一系列全面且系统的支持.通过这些全面而深入的合作，我们相信能够显著提升合作伙伴的</p>
                    <p class="text-center text-white d-none d-md-block">
                        品牌和业务能力，使其能够更加自信地面对来自各行业的不同业务挑战。我们期待与有志之士共同开拓广阔的市场，实现互利共赢，共创辉煌未来
                    </p>
                </div>
                <!-- 2 -->
                <div class="  d-flex justify-content-between  flex-md-row flex-column bg-cooperation-center pt-md-5 ">
                    <div class="text-md-end text-start  bg-cooperation-width ">
                        <div class="d-none d-md-block">
                            <div class="pb-2">
                                <span class="pe-2">免费UE场景搭建</span>
                                <img src="../image/partner/模块2/icon_1.png " class="img-fluid" alt="">
                            </div>
                            <p>根据提供的模型数据，搭建EarthSDK的UE工程，支持大屏和像素流模式，并返回源码工程文件</p>
                        </div>


                        <div class="d-none d-md-block pb-4">
                            <div class="pb-2">
                                <span class="pe-2">技术支持</span> <img src="../image/partner/模块2/icon_2.png "
                                    class="img-fluid" alt="">
                            </div>
                            <p>提供一对一技术开发指导</p>
                        </div>

                        <div class="d-none d-md-block">
                            <div class="pb-2">
                                <span class="pe-2">先用后买</span> <img src="../image/partner/模块2/icon_3.png"
                                    class="img-fluid" alt="">
                            </div>
                            <p>EarthSDK相关产品，在所有项目中免费用一年，用好了再购买。</p>
                        </div>

                    </div>
                    <div class="d-none d-md-block">
                        <img src="../image/partner/模块2/zhuangshi.png" alt="" class="img-fluid">
                    </div>
                    <div class="text-start bg-cooperation-width ">
                        <div class="d-none d-md-block">
                            <div class="pb-2 ">
                                <img src="../image/partner/模块2/icon_4.png" alt="" class="img-fluid "><span class="ps-2">
                                    定制开发</span>
                            </div>
                            <p>深入了解合作伙伴的需求，提供定制化的技术解决方案，确保技术问题得到及时解决</p>
                        </div>
                        <div class="d-none d-md-block">
                            <div class="pb-2">
                                <img src="../image/partner/模块2/icon_5.png" alt="" class="img-fluid "> <span
                                    class="ps-2">架构设计</span>
                            </div>
                            <p>根据合作伙伴的具体需求，提供专业的系统设计和指导，确保项目的顺利进行</p>
                        </div>
                        <div class="d-none d-md-block">
                            <div class="pb-2">
                                <img src="../image/partner/模块2/icon_6.png" alt="" class="img-fluid "> <span
                                    class="ps-2">系统培训</span>
                            </div>
                            <p>针对合作伙伴的技术人员和销售人员，提供从基础到高级的培训课程，全面提升团队的专业技能</p>
                        </div>
                    </div>
                </div>
                <!-- 2-移动端 -->
                <div class="d-block d-md-none d-flex justify-content-center bg-mobile-coop py-2">
                    <div class="d-flex justify-content-center mobile-coop py-3 ">
                        <div class="bg-cooperation-width ps-4 ">
                            <div class="">
                                <div class="pb-2 position-relative">
                                    <img src="../image/partner/模块2/icon_1.png" alt="" class="pe-2 img-fluid mobile-ico">
                                    <span>场景搭建 </span>
                                    <span class="mobile-coop-text">免费</span>
                                    <!-- <img src="../image/partner/模块2/free.png" alt=""  class="img-fluid coop-free position-absolute" /> -->
                                </div>
                            </div>
                            <div class="">
                                <div class="pb-2">
                                    <img src="../image/partner/模块2/icon_2.png" class="pe-2 img-fluid mobile-ico"
                                        alt=""><span>技术支持</span>
                                </div>
                            </div>
                            <div class="">
                                <div class="pb-2">
                                    <img src="../image/partner/模块2/icon_3.png" alt=""
                                        class="pe-2 img-fluid mobile-ico"><span>先用后买</span>
                                </div>
                            </div>
                        </div>
                        <div class=" bg-cooperation-width ps-4">
                            <div class="">
                                <div class="pb-2 ">
                                    <img src="../image/partner/模块2/icon_4.png" alt=""
                                        class="img-fluid mobile-ico2"><span class="ps-2">定制开发</span>
                                </div>
                            </div>
                            <div class="">
                                <div class="pb-2 ">
                                    <img src="../image/partner/模块2/icon_5.png" alt="" class="img-fluid mobile-ico2">
                                    <span class="ps-2">架构设计</span>
                                </div>
                            </div>
                            <div class="">
                                <div class="pb-2 ">
                                    <img src="../image/partner/模块2/icon_6.png" alt="" class="img-fluid mobile-ico2">
                                    <span class="ps-2">系统培训</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- 3 -->
                <div class="row row-cols-1 row-cols-md-3  hh text-start py-md-5  text-white">
                    <div class="col d-flex justify-content-center g-4 g-md-0 ">
                        <div class="bg-cooperation-base bg-cooperation-primary p-3 text-center position-relative">
                            <div class="pt-3">
                                <img src="../image/partner/模块2/gaoji.png" alt=""
                                    class="position-absolute top-0  start-0 img-fluid ">
                            </div>
                            <div class="coop-mobile-font fw-bold">初级伙伴 · 初识相知</div>
                            <div class=" pt-3 pb-3  text-center px-md-1 cooperation-box">
                                合作伙伴提供基础数据（dem高程数据，dom影像数据、倾斜数据、三维模型数据、BIM模型数据，矢量数据），我们负责免费搭建符合EarthSDK调用的UE工程文件，合作伙伴可以基于EarthSDK做项目展示和开发。
                            </div>
                            <p class="btn lh-1  download-btn mt-md-4 mt-3" @click="scrollToAnchor('code')">立即加入</p>

                        </div>
                    </div>

                    <div class="col d-flex justify-content-center g-4 g-md-0 ">
                        <div class="bg-cooperation-base bg-cooperation-middle p-3 text-center position-relative">
                            <div class="pt-3">
                                <img src="../image/partner/模块2/chuji.png" alt=""
                                    class="position-absolute top-0  start-0 img-fluid">
                            </div>
                            <div class="coop-mobile-font fw-bold">中级伙伴 · 相互宣传</div>
                            <div class=" pt-3 text-center px-md-1 cooperation-box">
                                合作伙伴分享基于EarthSDK开发的项目背景、视频、截图等资料（去掉保密信息），我们使用这些资料整理文章，发布在我们网站、微信公众号等宣传渠道。我们提供必要的技术支持，协助项目开发，并且提供EarthSDK和ESSS信令服务器的一年期免水印部署授权。
                            </div>
                            <p class="btn lh-1  download-btn mt-3 mt-md-3" @click="scrollToAnchor('code')">立即加入</p>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-4 g-md-0 ">
                        <div class="bg-cooperation-base bg-cooperation-advanced p-3 text-center position-relative">
                            <div class="pt-3">
                                <img src="../image/partner/模块2/zhongji.png" alt=""
                                    class="position-absolute top-0  start-0 img-fluid">
                            </div>
                            <div class="coop-mobile-font fw-bold">高级伙伴 · 合作共赢</div>
                            <div class=" pt-3 pb-3 text-center px-md-1 cooperation-box">
                                合作伙伴付费获得一对一专业技术支持、系统性使用培训、定制性功能开发、系统架构设计等更专业服务，帮助伙伴赢得客户信任，实现利润增长。
                            </div>
                            <p class="btn lh-1  download-btn mt-md-5 mt-3" @click="scrollToAnchor('code')">立即加入</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- 免费使用产品 -->
        <section>
            <div class="bg-free container-custom text-white ">
                <h3 class="text-center py-md-5 pt-5">免费使用产品</h3>
                <div class="row row-cols-2 row-cols-md-4  text-start pt-3 pb-5  mobile-flex-free ">
                    <div class="col d-flex justify-content-center g-2 g-md-0 mobile-img-free ">
                        <div class="bg-freecharge p-3  ">
                            <div class="pt-3">
                                <p class="free-title">ESSS信令服务器</p>
                                <p class="free-line  d-none d-md-block"></p>
                            </div>
                            <div class="text-wrap free-text d-none d-md-block mt-auto">
                                UE像素流管理平台，一键启动，支持性能监控，支持多并发实例管理，安全稳定</div>
                            <div class="pt-md-5 pt-3">
                                <a href="https://bjxbsj.cn/esss.html" class="free-a " target="_blank" role="button"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-2 g-md-0 mobile-img-free ">
                        <div class="bg-freecharge p-3  ">
                            <div class="pt-3">
                                <p class="  free-title">ESWebView</p>
                                <p class="free-line  d-none d-md-block"></p>
                            </div>
                            <div class="text-wrap free-text d-none d-md-block  mt-auto">
                                UE网页插件，支持网页H5新特性、支持UE与JS通信等支持独立的js开发接口、支持鼠标穿透交互和浏览器调试等</div>
                            <div class="pt-md-4 pt-3">
                                <a href="https://bjxbsj.cn/eswebview.html" class="free-a " target="_blank"
                                    role="button"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-2 g-md-0 mobile-img-free ">
                        <div class="bg-freecharge p-3  ">
                            <div class="pt-3">
                                <p class="  free-title">EarthSDK JS</p>
                                <p class="free-line  d-none d-md-block"></p>
                            </div>
                            <div class="text-wrap free-text d-none d-md-block  mt-auto">
                                地球可视化二次开发包，内置上百种对象，一套代码，实现Cesium和UE「双引擎」可视化</div>
                            <div class="pt-md-4 pt-3">
                                <a href="https://wzonfjgpzd.feishu.cn/docx/NUssdX48WobIqlxU9dFcaRDhnwc" class="free-a "
                                    target="_blank" role="button"></a>
                            </div>

                        </div>
                    </div>
                    <div class="col d-flex justify-content-center g-2 g-md-0 mobile-img-free ">
                        <div class="bg-freecharge p-3 ">
                            <div class="pt-3">
                                <p class="free-title">ES for UE</p>
                                <p class="free-line d-none d-md-block"></p>
                            </div>
                            <div class="text-wrap free-text d-none d-md-block  mt-auto">
                                JS与UE的通信插件，实现JS与UE的无缝通信，实现前端开发UE4/5数字孪生</div>
                            <div class="pt-md-5 pt-3">
                                <a href="https://bjxbsj.cn/downcenter.html?product=ESForUE" class="free-a "
                                    target="_blank" role="button"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <!-- 合作流程 -->
        <section>
            <div class="container-custom  py-md-5 bg-flow text-white d-none d-md-block">
                <h3 class="py-md-5 pt-3 text-center">合作流程</h3>
                <div class="flow-text d-flex  align-items-center justify-content-center  py-5">
                    <div class="d-flex flex-column align-items-center  position-relative">
                        <img src="../image/partner/模块4/1.png" alt="" class="img-fluid pe-5 ps-5">
                        <p class="position-absolute ">合作洽谈</p>
                    </div>
                    <img src="../image/partner/模块4/jiantou.png" alt="" class="img-fluid ">
                    <div class="d-flex flex-column align-items-center  position-relative">
                        <img src="../image/partner/模块4/2.png" alt="" class="img-fluid ps-5 pe-5">
                        <p class="position-absolute ">签订协议</p>
                    </div>
                    <img src="../image/partner/模块4/jiantou.png" alt="" class="img-fluid ">
                    <div class="d-flex flex-column align-items-center  position-relative">
                        <img src="../image/partner/模块4/3.png" alt="" class="img-fluid pe-5 ps-5">
                        <p class="position-absolute ">业务培训</p>
                    </div>
                    <img src="../image/partner/模块4/jiantou.png" alt="" class="img-fluid ">
                    <div class="d-flex flex-column align-items-center  position-relative">
                        <img src="../image/partner/模块4/4.png" alt="" class="img-fluid ps-5 pe-5">
                        <p class="position-absolute ">开展合作</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- 合作流程移动端 -->
        <section>
            <div class="container-custom  py-md-5 bg-flow text-white position-relative d-md-none d-block">
                <h3 class=" pt-4  position-absolute mobile-flow-title">合作流程</h3>
                <img src="../image/partner/模块4/hezuo.jpg" alt="" class="img-fluid">
            </div>
        </section>

        <!-- 二维码 -->
        <section>
            <div
                class="bg-code d-flex justify-content-center align-items-center flex-column py-5 text-center text-white">
                <div class="pb-4" id="code">
                    <img src="../image/partner/模块5/ma.png" alt="" class="img-fluid">
                </div>
                <p class="code-frame py-1 ">
                    扫描二维码，添加联系人
                </p>
            </div>
        </section>
    </div>
</template>
<script setup>
// 锚点定位
function scrollToAnchor(anchorId) {
    const element = document.getElementById(anchorId);
    if (!element) return
    document.querySelector("#code").scrollIntoView(true);
}
</script>
<style scoped src="../style/partners-program.css"></style>