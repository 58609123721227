<template>
    <div class="bg-all ">
        <!-- 表格 -->
        <section class="text-white container-custom">
            <div class="d-flex pt-5">
                <img src="../image/price/logo.png" alt="" class="img-fluid head-logo">
                <img src="../image/price/logo_.png" alt="" class="img-fluid head-logo2">
            </div>
            <div class="d-flex justify-content-center  head-img">
                <img src="../image/price/wenzi.png" alt="" class="img-fluid ">
            </div>
            <!-- EarthSDK -->
            <div class=" top-space">
                <div class="text-center d-flex align-items-center flex-column">
                    <p class="font-size-34 biaoti-bg py-3 head-title">EarthSDK三维地球数据融合呈现软件</p>
                    <div class="font-size-23 d-none d-md-block">
                        <p class="p-0 m-0">EarthSDK 开源免费地球可视化二次开发框架 </p>
                        <p>一套代码,实现Cesium和UE「双引擎」可视化</p>
                    </div>
                </div>
            </div>

            <!-- 大屏模式 -->
            <div>
                <div class="d-flex justify-content-center font-size-58">
                    <p class="gradual-change daping-left daping-left-mobile"><img src="../image/price/font/1.png"
                            class="img-fluid" alt="">
                    </p>
                    <p class="line-center mt-5 daping-center"></p>
                    <p class="gradual-change daping-right"><img src="../image/price/font/1_.png" class="img-fluid"
                            alt=""></p>
                </div>
                <div class=" table-responsive text-center bg-table px-3 pt-3 mt-5">
                    <table class="table table-bordered border   table-hover ">
                        <thead class=" table-title ">
                            <tr class="font-size-21">
                                <th scope="col">名称</th>
                                <th scope="col">免费使用</th>
                                <th scope="col">收费使用</th>
                                <th scope="col">一年</th>
                                <th scope="col">永久版</th>
                            </tr>
                        </thead>
                        <tbody class="table-subtitle font-size-19">
                            <tr>
                                <td scope="row">EarthSDK JS</td>
                                <td class="" colspan="2">全部功能无限制,部分代码开源(gitee)</td>
                                <td class="color-green ">0</td>
                                <td class="color-green ">0</td>
                            </tr>
                            <tr>
                                <td scope="row">ESForUE插件</td>
                                <td class="table-style-middle" rowspan="3">全部功能,极小水印</td>
                                <td class="table-style-middle" rowspan="2">全部功能,无水印</td>
                                <td class="color-green ">6800</td>
                                <td class="color-green ">16800</td>
                            </tr>
                            <tr>
                                <td scope="row">ESWebView插件</td>
                                <td class="">1800</td>
                                <td class="color-green ">5800</td>
                            </tr>
                            <tr>
                                <td scope="row">全部套餐</td>
                                <td class="">全功能,无水印,无实例限制</td>
                                <td class="color-green ">8600</td>
                                <td class="color-green ">22600</td>
                            </tr>
                            <tr>
                                <td scope="row"> 备注</td>
                                <td class="" colspan="4">永久版,购买后2年内免费更新.全部套餐赠送一个月一对一技术支持</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- 分布式渲染模式 -->
            <div class="mt-5">
                <div class="d-flex justify-content-center font-size-58">
                    <p class="gradual-change daping-left"><img src="../image/price/font/2.png" class="img-fluid" alt="">
                    </p>
                    <p class="line-center mt-5 daping-center"></p>
                    <p class="gradual-change daping-right"><img src="../image/price/font/2_.png" class="img-fluid"
                            alt=""></p>
                </div>
                <!-- 1 -->
                <p class="font-size-21 px-5 font-mobile14">主控节点：由于主要控制节点渲染并管理子节点服务器</p>
                <div class=" table-responsive text-center bg-table px-3 pt-3 mt-2">
                    <table class="table table-bordered border   table-hover ">
                        <thead class=" table-title ">
                            <tr class="font-size-21">
                                <th scope="col">名称</th>
                                <th scope="col">免费使用</th>
                                <th scope="col">收费使用</th>
                                <th scope="col">一年</th>
                                <th scope="col">永久版</th>
                            </tr>
                        </thead>
                        <tbody class="table-subtitle font-size-19">
                            <tr>
                                <td scope="row">EarthSDK JS</td>
                                <td class="" colspan="2">全部功能无限制,部分代码开源(gitee)</td>
                                <td class="color-green ">0</td>
                                <td class="color-green ">0</td>

                            </tr>
                            <tr>
                                <td scope="row">ESForUE插件</td>
                                <td class="table-style-middle" rowspan="2">全部功能,极小水印</td>
                                <td class="table-style-middle" rowspan="2">全部功能,无水印</td>
                                <td class="color-green ">6800</td>
                                <td class="color-green ">16800</td>
                            </tr>
                            <tr>
                                <td scope="row">ESWebView插件</td>
                                <td class="">1800</td>
                                <td class="color-green ">5800</td>
                            </tr>
                            <tr>
                                <td scope="row">ESSS信令服务器</td>
                                <td class="">全部功能,限制1实例</td>
                                <td class="">全部功能,无实例限制</td>
                                <td class="color-green ">6800</td>
                                <td class="color-green ">16800</td>
                            </tr>
                            <tr>
                                <td scope="row">全部套餐</td>
                                <td class="">/</td>
                                <td class=""> 全功能,无水印,无实例限制</td>
                                <td class="color-green ">12800</td>
                                <td class="color-green ">30800</td>
                            </tr>
                            <tr>
                                <td scope="row"> 备注</td>
                                <td class="" colspan="4">永久版,购买后2年内免费更新.全部套餐赠送一个月一对一技术支持</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <!-- 2 -->
                <p class="font-size-21 px-5 pt-5 font-mobile14">渲染节点：分布式渲染阶段</p>
                <div class=" table-responsive text-center bg-table px-3 pt-3 mt-2">
                    <table class="table table-bordered border   table-hover ">
                        <thead class=" table-title ">
                            <tr class="font-size-21">
                                <th scope="col">名称</th>
                                <th scope="col">一年期</th>
                                <th scope="col">永久版</th>
                            </tr>
                        </thead>
                        <tbody class="table-subtitle font-size-19">

                            <tr>
                                <td scope="row">ESForUE插件</td>
                                <td class="color-green ">1200</td>
                                <td class="color-green ">3200</td>
                            </tr>
                            <tr>
                                <td scope="row">ESWebView插件</td>
                                <td class="">400</td>
                                <td class="color-green ">1400</td>
                            </tr>
                            <tr>
                                <td scope="row">ESSS信令服务器</td>
                                <td class="color-green ">1200</td>
                                <td class="color-green ">3200</td>
                            </tr>
                            <tr>
                                <td scope="row">套餐包</td>
                                <td class="color-green ">2800</td>
                                <td class="color-green ">7800</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>


            <!-- earthsdk -->
            <div>
                <div class="d-flex justify-content-center font-size-58 mt-5">
                    <p class="gradual-change daping-left-earthsdk"><img src="../image/price/font/3.png"
                            class="img-fluid" alt="">
                    </p>
                    <p class="line-center-earthsdk mt-5 daping-center"></p>
                    <p class="gradual-change daping-right "><img src="../image/price/font/3_.png" class="img-fluid"
                            alt=""></p>
                </div>
                <div class=" table-responsive text-center bg-table px-3 pt-3 mt-5">
                    <table class="table table-bordered border   table-hover ">
                        <thead class=" table-title ">
                            <tr class="font-size-21">
                                <th scope="col">支持方式</th>

                                <th scope="col">微信群支持</th>
                                <th scope="col">深度一对一支持示例代码+远程桌面（赠送线下集训）</th>
                            </tr>
                        </thead>
                        <tbody class="table-subtitle font-size-19">
                            <tr>
                                <td scope="row"> 技术支持费用</td>
                                <td>0</td>
                                <td class="">2 万/月</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </section>
        <!-- pipser表格 -->
        <section class="text-white bg-pipser">

            <div class="mt-5 py-5 container-custom">
                <div class="text-center d-flex align-items-center flex-column">
                    <p class="font-size-34 biaoti-bg head-title py-3">PipeSer管线管网云服务平台</p>
                    <div class="font-size-23 font-mobile">
                        <p class="pt-3 m-0">轻量建模 - 浏览器拖拖拽拽,即见建模成果 </p>
                        <p class="p-0 m-0">无限承载 轻松承载城市级百万、千万管点,无限范围,无限承载能力</p>
                    </div>
                </div>

                <!-- pipser -->
                <div>
                    <div class=" table-responsive text-center bg-table px-3 pt-3 mt-5">
                        <table class="table table-bordered border   table-hover ">
                            <thead class=" table-title ">
                                <tr class="font-size-21">
                                    <th scope="col">名称</th>
                                    <th scope="col">免费使用</th>
                                    <th scope="col">收费使用</th>
                                    <th scope="col">一年有效期</th>
                                    <th scope="col">永久有效期</th>
                                </tr>
                            </thead>
                            <tbody class="table-subtitle font-size-19">
                                <tr>
                                    <td scope="row">1万管点</td>
                                    <td rowspan="4" class="table-style-middle">在线使用</td>
                                    <td rowspan="4" class="table-style-middle">离线部署</td>
                                    <td>5000</td>
                                    <td>25000</td>
                                </tr>

                                <tr>
                                    <td scope="row">10万管点</td>
                                    <td>16000</td>
                                    <td>50000</td>

                                </tr>
                                <tr>
                                    <td scope="row">100万管点</td>
                                    <td>40000</td>
                                    <td>100000</td>
                                </tr>
                                <tr>
                                    <td scope="row">不限制</td>
                                    <td>80000</td>
                                    <td>200000</td>
                                </tr>
                                <tr>
                                    <td scope="row"> 备注</td>
                                    <td class="" colspan="4">永久版,购买后2年内免费更新</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>



                </div>

            </div>
        </section>

        <!-- modelser表格 -->

        <section class="text-white ">

            <div class="mt-5 py-5 container-custom">
                <div class="text-center d-flex align-items-center flex-column">
                    <p class="font-size-34 biaoti-bg head-title py-3">ModelSer实景三维数据分布式管理平台</p>
                    <div class="font-size-23 font-mobile">
                        <p class="pt-3 m-0">分布式架构,并行处理模型数据 </p>
                        <p class="p-0 m-0">经测试,增加一个节点,处理速度指数级上升</p>
                    </div>
                </div>
                <div>
                    <div class=" table-responsive text-center bg-table px-3 pt-3 mt-5">
                        <table class="table table-bordered border   table-hover ">
                            <thead class=" table-title ">
                                <tr class="font-size-21">
                                    <th scope="col"></th>
                                    <th scope="col">节点个数</th>
                                    <th scope="col">年版</th>
                                    <th scope="col">永久版</th>

                                </tr>
                            </thead>
                            <tbody class="table-subtitle font-size-19">

                                <tr>
                                    <td scope="row">门槛价</td>
                                    <td>4</td>
                                    <td>11800 </td>
                                    <td>58800 </td>
                                </tr>
                                <tr>
                                    <td scope="row">增加节点</td>
                                    <td>1</td>

                                    <td>2880</td>
                                    <td>7800</td>
                                </tr>
                                <tr>
                                    <td scope="row"> 备注</td>
                                    <td class="" colspan="4">增加节点仅在更新期内有效,年版更新期为同期限,永久版更新时间2年</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>



                </div>

            </div>
        </section>

        <!-- cesiumlab表格 -->
        <section class="text-white bg-pipser">

            <div class="mt-5 py-5 container-custom">
                <div class="text-center d-flex align-items-center flex-column">
                    <p class="font-size-34 biaoti-bg head-title py-3">CesiumLab地理信息基础数据处理平台</p>
                    <div class="font-size-23 font-mobile">
                        <p class="pt-3 m-0">数据转换 更胜一筹，全国40000+创作者 </p>
                        <p class="p-0 m-0">数千家设计院、测绘单位和设计公司都在用</p>
                    </div>
                </div>

                <div>
                    <div class=" table-responsive  text-center bg-table px-3 pt-3 mt-5">
                        <div class="table-self">
                            <table class="table table-bordered border  table-striped  table-hover ">
                                <thead class=" table-title ">
                                    <tr class="font-size-21">
                                        <th scope="col">功能模块</th>
                                        <th scope="col">免费使用</th>
                                        <th scope="col">收费使用</th>
                                        <th scope="col">单机版</th>
                                        <th scope="col">USB版</th>
                                    </tr>
                                </thead>
                                <tbody class="table-subtitle font-size-19">
                                    <tr>
                                        <td scope="row">地形切片</td>
                                        <td colspan="2" class="table-style-middle">全部功能</td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td scope="row">影像切片</td>
                                        <td>基础功能</td>
                                        <td>自定义瓦片尺寸</td>
                                        <td rowspan="8" class="table-style-middle">800(月)<br /> 5800(年)</td>
                                        <td rowspan="8" class="table-style-middle">7800(年)<br /> 46800(永久)</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">点云切片</td>
                                        <td>基础功能</td>
                                        <td> 点云压缩</td>
                                    </tr>

                                    <tr>
                                        <td scope="row">实例化模型切片</td>
                                        <td colspan="2" class="table-style-middle">全部功能</td>
                                    </tr>

                                    <tr>
                                        <td scope="row">倾斜模型切片</td>
                                        <td>基础功能</td>
                                        <td>顶层重建、KTX2纹理压缩</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">通用模型切片</td>
                                        <td>基础功能</td>
                                        <td>投影变换、Ktx2纹理压缩、BIM模型处理</td>
                                    </tr>


                                    <tr>
                                        <td scope="row">地形切片服务</td>
                                        <td colspan="2" rowspan="3" class="table-style-middle">全部功能</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">影像切片服务</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">模型切片服务</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
</script>
<style>
/* .head-img img {
    width: 700px;
} */

.head-img {
    padding-top: 10%;
}

.bg-pipser {
    background: url(../image/price/beijingkuang_1.png) no-repeat;
    background-size: 100% 100%;
}

.table-style-middle {
    vertical-align: middle;
}

.bg-table {
    background: url(../image/price/1.png) no-repeat;
    background-size: 100% 100%;
    overflow: visible;
    padding: 4px;
}

.table-subtitle {
    height: 49px;
    line-height: 49px;
}

.table-subtitle td {
    color: white;
    background: transparent;

}

.table-title {
    font-size: 16px;
    font-weight: bold;
    height: 49px;
    line-height: 49px;
}

.table-title th {
    background: #2c4ea0;
    color: white;

}

.table-responsive .border {
    border-color: rgb(167, 231, 255, .3) !important;
}

.daping-left {
    width: 35%;
    border-left: 13px solid #04BBFF;
    padding-left: 48px;
}

/* .daping-left-mobile img {
    width: 152px !important;
}

.daping-left img {
    width: 245px;
}
.daping-right img {
    width: 50px;
} */

.daping-left-earthsdk {
    width: 50%;
    border-left: 13px solid #04BBFF;
    padding-left: 48px;
    margin-right: 122px;
}

.daping-right {
    width: 10%;
    text-align: right;
}

.gradual-change {
    background: linear-gradient(0deg, #04BBFF 50%, #446CFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.line-center-earthsdk {
    width: 40%;
    height: 1px;
    border-top: solid #04BBFF 1px;
    opacity: 0.7;
    padding: 0 10px 0 10px;

}

.line-center {
    width: 55%;
    height: 1px;
    border-top: solid #04BBFF 1px;
    opacity: 0.7;
    padding: 0 10px 0 10px;

}

.top-space {
    margin-top: 27%;
}

.biaoti-bg {
    background: url(../image/price/biaoti.png) no-repeat;
    background-size: 100% 100%;
    width: 23em;
}

.head-logo {
    width: 52px;
    height: 52px;
    vertical-align: middle;
}

@media(max-width:768px) {
    .head-logo {
        width: 25px;
        height: 25px;
    }

    .head-title {
        font-size: 19px !important;
    }

    .head-logo2 {
        width: 37%;
    }

    .daping-left {
        width: 60%;
        border-left: 3px solid #04BBFF;
        padding-left: 15px;
    }

    .daping-left-mobile {
        padding: 0 40px 0 40px;
    }

    .table-subtitle {
        font-size: 12px !important;
    }

    .table-title tr {
        font-size: 0.6em;
    }

    .daping-left-earthsdk {
        width: 70%;
        border-left: 3px solid #04BBFF;
        margin-right: 1px;
        padding-left: 10px;
    }

    .daping-right {
        width: 10%;
        text-align: right;
    }

    .line-center-earthsdk {
        width: 20%;
    }

    .font-mobile {
        font-size: 16px !important;
    }

    .line-center {
        width: 30%;

    }

    .biaoti-bg {
        width: 100%;
    }

    .top-space {
        margin-top: 10%;
    }

    .font-mobile14 {
        font-size: 14px !important;
    }

    .bg-all {
        background-attachment: fixed !important;
    }

    .table-self {
        height: 550px;
        overflow: scroll;
    }

    .table-self thead {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .head-img img {
        width: 173px !important;
    }
    .container-custom {
    padding: 10px !important;
}
}

.bg-all {
    background: url(../image/price/test2.jpg) no-repeat;
    background-size: cover;
}

.container-custom {
    padding: 0 4% 0 4%;
}
</style>