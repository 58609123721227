// 导入Vue和VueRouter模块
import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '@/view/HomePage.vue';
import PartnersProgram from '@/view/PartnersProgram.vue';
import PriceList from '@/view/PriceList.vue';
// 创建VueRouter实例，并配置路由规则
const  routes= [
        {
          path: '/',
          component: HomePage
        },
        {
          path: '/PartnersProgram',
          component: PartnersProgram
        },
        {
          path: '/PriceList',
          component: PriceList
        },
      ]

        const router = createRouter({
            history: createWebHashHistory(),
            routes
        })
export default router
