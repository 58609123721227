<template>
  <HeaderCom></HeaderCom>
  <router-view></router-view>
  <!-- <FooterCom></FooterCom> -->
</template>

<script setup>
import HeaderCom from './components/HeaderCom.vue';
// import FooterCom from './components/FooterCom.vue';


</script>
<script>
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    return { router };
  }
};
</script>
<style>

#app{
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}
</style>
