<template>
    <div>
        <footer class="bg-dark text-white py-4">
            <div class="container custom-padding">
                <div class="row">
                    <div class="col-lg-2">
                        <p class="mb-4 font-size-14">企业产品</p>
                        <ul class="list-unstyled font-size-12">
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/cesiumlab.html">Cesiumlab地理信息基础数据处理平台</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/pipeser.html">PipeSer管线管网云服务</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/modelser.html">ModelSer实景三维数据分布式管理平台</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="#"></a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://earthsdk.com/">EarthSDK地球可视化开发包</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/eswebview.html">ESWebView插件</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/esss.html">ESSS信令服务器</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2">
                        <p class="font-size-14">服务支持</p>
                        <ul class="list-unstyled font-size-12">
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/portsolution.html">智慧港口解决方案</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/forestrysoslution.html">智慧林业解决方案</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/datasolution.html">大数据预处理与可视化方案定制</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.huanshu3d.cn/#/home">场景建模与设计</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="#"></a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/course.html">培训课程</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/tech.html">技术支持</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2">
                        <p class="font-size-14">产品购买</p>
                        <ul class="list-unstyled font-size-12">
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/cesiumlabpurchase.html">CesiumLab地理信息基础数据处理平台</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/pipeserpurchase.html">PipeSer管线管网云服务</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/modelserpurchase.html">ModelSer实景三维数据分布式管理平台</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="#"></a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/tech.html">EarthSDK三维地球开发包</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/essspurchase.html">ESSS信令服务器</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/eswebviewpurchase.html">ESWebView插件</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2">
                        <p class="font-size-14">关于我们</p>
                        <ul class="list-unstyled font-size-12">
                            <li><a class="text-white d-inline-block mb-2"  target="_blank"  href="https://wzonfjgpzd.feishu.cn/docx/NUssdX48WobIqlxU9dFcaRDhnwc">联系我们</a></li>
                            <li><a class="text-white d-inline-block mb-2"  target="_blank" href="https://www.bjxbsj.cn/tech.html">技术支持</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-3 ">
                        <p class="font-size-14">联系我们</p>
                        <p class="text-color font-size-12">关注微信公众《地球可视化实验室》提供人工技术支持</p>
                        <p class="text-color font-size-12">工作日 9:00 - 18:30</p>
                        <p class="text-color font-size-12">商务合作： <br>16619955894（苏经理）</p>
                        <img src="../image/erweima.jpg" alt="" style="height: 100px;width: 100px;">
                    </div>
                </div>
                <p class="mt-4  mb-0 text-center pb-custom border-top-white  py-2 font-size-12">© 2017-2024 北京西部世界科技有限公司.
                    京ICP备17022303号-4</p>
            </div>
        </footer>
    </div>
</template>

<script setup>


</script>

<style scoped>

@media (max-width: 992px) {

    footer{
 display: none;   
}
    .container-fluid.custom-padding {
        text-align: center;
    }

    ul.list-unstyled.font-size-12 li a {
        display: block;
        padding: 5px 0;
    }

    .font-size-12 {
        font-size: 16px !important;
    }
}

a {
    text-decoration: none;
    color: #8C96A5 !important;
}

.text-color {
    color: #8C96A5 !important;

}

.container-fluid.custom-padding {
    padding-left: 260px;
    padding-right: 260px;
    max-width: 100%;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .container-fluid.custom-padding {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.font-size-12 {
    font-size: 12px !important;
}

.pb-custom {
    padding-bottom: 1px !important;
    margin-bottom: 0 !important;
}

.border-top-white {
    border-top: 1px solid #333333;
    color: #8C96A5;
}
</style>
